import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "BoxScore",
  code: "BoxScore"
};
export const Wrapper = ({
  children
}) => <div className="w-[375px]">{children}</div>;
export const _frontmatter = {
  "menuLabel": "Box Score",
  "sortOrder": 2.3
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Box Score`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-boxscore--pre-game-horizontal" wrapper={Wrapper} wrapperCond={["PreGame Horizontal", "PreGame Vertical", "PreGame Skeleton"]} mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { BoxScore } from "@caesars-digital/caesars-ui";
`}</code></pre>
    <p>{`The Box Score component can be implemented in three different states.  `}</p>
    <h4>{`Pre-Game`}</h4>
    <p><inlineCode parentName="p">{`gameState="pregame"`}</inlineCode></p>
    <p>{`This is a pre-game version of the Box Score, and shows event start time as well as other key information like competitor information, when the match is starting and if a live stream is available.`}</p>
    <h4>{`Live`}</h4>
    <p><inlineCode parentName="p">{`gameState="live"`}</inlineCode></p>
    <p>{`This is a live state of the BoxScore component and is meant to be updated with new data to keep track of the event state.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is not released yet, only Pre-Game game state is available.`}</p>
    </blockquote>
    <h4>{`Finished`}</h4>
    <p><inlineCode parentName="p">{`gameState="finished"`}</inlineCode></p>
    <p>{`This state is when a game has already concluded and provides the end results as well as any other relevant information from the game.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is not released yet, only Pre-Game game state is available.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10">
  <span className="text-red-600">*</span> indicates required.
    </div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
   The Box Score component is easily perceivable by all users, including those with visual impairments. The component uses clear and descriptive labels for all elements. It is also designed to be accessible to screen readers so that visually impaired users can access the match details. The component also has sufficient contrast between text and background colors, ensuring that it is readable.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    This component is operable by all users, including those with physical impairments or using assistive technology. The component is easily navigable using keyboard controls or screen readers.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The Box Score's purpose is clear to users, with labels that are easy to understand and descriptive. Users can easily determine when the match is starting, who the teams are and whether or not a live stream is available. The match details are also clearly labeled and accessible to screen readers.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Box Score is compatible with assistive technologies and functions consistently across different browsers and platforms.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      